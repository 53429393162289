import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/yellow';

let theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: 48,
      fontWeight: 500,
      marginTop: 16,
      marginBottom: 16,
    },
    h2: {
      marginTop: 32,
      marginBottom: 16,
      fontSize: 36,
      fontWeight: 500,
    },
    h3: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 24,
    },
    subtitle1: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 25,
    },
    body1: {
      fontSize: 20,
    },
  },
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: green,
  },
});

theme = responsiveFontSizes(theme, {});
export default theme;
