/* eslint-disable import/prefer-default-export, react/prop-types */

import React from 'react';
import TopLayout from './src/components/TopLayout';
import Helmet from 'react-helmet';
// normalize CSS across browsers
import './src/normalize.css';
// custom CSS styles
import '@fontsource/domine';
import './src/styles/global.css';
import './src/style.css';

// Highlighting for code blocks
import 'prismjs/themes/prism.css';

export const wrapRootElement = ({ element }) => {
  return (
    <TopLayout>
      <Helmet>
        <meta name="slack-app-id" content="A011KDRQZNU" />
      </Helmet>
      {element}
    </TopLayout>
  );
};
